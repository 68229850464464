<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="offline cameras"
          show-expand
          single-expand
          sort-by="lastOnlineAt"
          item-key="exid"
          :headers="headers"
          :expanded.sync="expanded"
          :provider="reportTableProvider"
          :filter-fields="filterFields"
          :show-bookmarks="false"
          @change="fetchLatestNotes"
        >
          <template #actions-left>
            <ERow class="my-0">
              <ECol cols="8" class="pb-0">
                <Autocomplete
                  v-model="selectedOwner"
                  item-value="id"
                  item-text="name"
                  label="Filter by Owner"
                  :outlined="false"
                  hide-details
                  resource="users"
                  :list-items="ownerItems"
                  clearable
                />
              </ECol>
              <ECol cols="12" sm="2" md="2" lg="2" class="pl-0">
                <v-btn color="primary" small @click="doSearch">Filter</v-btn>
              </ECol>
            </ERow>
          </template>
          <template #expanded-item="{ headers: tableHeaders, item }">
            <td :colspan="tableHeaders.length" class="pa-0">
              <CameraLogs
                :camera="item"
                :read-only="!canEditCameras"
                @refresh-last-notes="fetchLatestNotes"
              />
            </td>
          </template>
          <template #item.publicNote="{ item }">
            <div class="camera-note">
              {{ item.publicNote }}
            </div>
          </template>
          <template #item.privateNote="{ item }">
            <div class="camera-note">
              {{ item.privateNote }}
            </div>
          </template>
          <template #item.name="{ item }">
            <span
              class="primary--text cursor-pointer"
              @click="cameraDialogStore.openDialog({ camera: item.exid })"
            >
              {{ item.name }}
            </span>
            <OpenInNewWindowButton
              hint="Open camera in new tab"
              :target-link="getCameraDialogUrl(item.exid)"
            />
          </template>
          <template #item.zohoId="{ item }">
            <OpenInZohoButton
              v-if="item.zohoId"
              :zoho-id="item.zohoId"
              type="camera"
            />
          </template>
          <template #item.billPayer="{ item }">
            <div v-if="item.sims && item.sims.length > 0">
              <span v-for="(sim, index) in item.sims" :key="index">
                {{
                  index !== item.sims.length - 1
                    ? `${sim.billPayer},`
                    : sim.billPayer
                }}
              </span>
            </div>
            <span v-else>-</span>
          </template>
          <template #item.simNumber="{ item }">
            <SimsColumn
              :item="item"
              number-field="number"
              :disabled="!canViewSims"
              @open-sms-history-dialog="openSmsHistoryDialog"
            />
          </template>
          <template #item.voltage="{ item }">
            <div v-if="item.batteryReadingsLastVoltage">
              <span
                class="primary--text cursor-pointer d-flex justify-center align-center"
                :class="{ 'low-voltage': item.batteryReadingsLastVoltage < 11 }"
                @click="openBatteryReadingsDialog(item.exid)"
              >
                {{ item.batteryReadingsLastVoltage }}V ({{
                  $moment(item.batteryReadingsLastSeen).fromNow()
                }})
              </span>
            </div>
          </template>
          <template #item.cameraHost="{ item }">
            {{ item.cameraHost }}
            <OpenInNewWindowButton
              :target-link="`http://${item.cameraHost}`"
              hint="Open Router"
            />
          </template>
          <template #item.status="{ item }">
            <v-edit-dialog
              v-if="canEditCameras"
              large
              :return-value.sync="item.status"
              @save="save(item.exid, item.status)"
            >
              <span :class="getStatusColor(item.status)">
                {{ getStatusText(item.status) }}
              </span>
              <v-icon small color="primary"> fa-pencil-alt </v-icon>
              <template #input>
                <v-select
                  v-model="item.status"
                  class="mt-3"
                  :items="statusesItems"
                  label="Status"
                  item-text="name"
                  item-value="value"
                  @change="onChange(item)"
                ></v-select>
              </template>
            </v-edit-dialog>
            <span v-else :class="getStatusColor(item.status)">
              {{ getStatusText(item.status) }}
            </span>
          </template>
          <template #item.powerType="{ item }">
            {{ getPowerTypeText(item.powerType) }}
          </template>
          <template #item.powerSchedule="{ item }">
            {{ getPowerScheduleText(item.powerSchedule) }}
          </template>
          <template #item.lastOnlineAt="{ item }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ $moment.tz(item.lastOnlineAt, item.timezone).fromNow() }}
                </span>
              </template>
              <span>
                {{ $moment.tz(item.lastOnlineAt, item.timezone) }}
              </span>
            </v-tooltip>
          </template>
        </ReportTable>
        <SmsHistoryDialog
          v-model="showSmsHistoryDialog"
          :sim="selectedSimNumber"
          :selected-sim="selectedSim"
          :read-only="!canSendSms"
        />
        <BatteryReadingsDialog
          v-if="selectedCameraExid"
          :id="selectedCameraExid"
          :show-dialog="showBatteryReadingsDialog"
          @closed="showBatteryReadingsDialog = false"
        />
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import headers from "@/components/offlineCameras/offlineCamerasHeaders"
import filterFields from "@/components/offlineCameras/offlineCamerasSearchFilters"
import {
  CameraStatuses,
  PowerSchedules,
  PowerTypes,
} from "@/components/constants"
import CameraUtils from "@/mixins/cameraUtils"
import { CameraStatus } from "@evercam/shared/types/camera"
import SmsHistoryDialog from "@/components/sims/SmsHistoryDialog.vue"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import SimsColumn from "@/components/SimsColumn"
import BatteryReadingsDialog from "@/components/batteries/BatteryReadingsDialog"
import CameraLogs from "@/components/offlineCameras/CameraLogs"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  components: {
    SmsHistoryDialog,
    Autocomplete,
    OpenInNewWindowButton,
    OpenInZohoButton,
    SimsColumn,
    BatteryReadingsDialog,
    CameraLogs,
    ReportTable,
  },
  mixins: [CameraUtils],
  props: {
    report: {
      type: String,
      default: "offline-cameras",
    },
  },
  data: () => {
    return {
      expanded: [],
      headers: [],
      filterFields: {},
      selectedOwner: {
        id: 13959,
        name: "Construction Evercam",
      },
      ownerItems: [],
      sims: [],
      statusesItems: CameraStatuses,
      showSmsHistoryDialog: false,
      selectedSimNumber: "",
      selectedSim: null,
      showBatteryReadingsDialog: false,
      selectedCameraExid: null,
    }
  },
  computed: {
    ...mapStores(useReportStore, useCameraDialogStore),
    reportTableProvider() {
      return this.report === "offline-cameras"
        ? AdminApi.offlineCameras.getOfflineCameras
        : AdminApi.offlineCameras.getSiteVisitCameras
    },
    canEditCameras() {
      return this.$permissions.user.can(PERMISSIONS.CAMERAS.UPDATE)
    },
    canViewSims() {
      return (
        this.$permissions.user.can(PERMISSIONS.SIMS_AND_SMS.VIEW_SIMS) &&
        this.$permissions.user.can(PERMISSIONS.SIMS_AND_SMS.VIEW_SMS)
      )
    },
    canSendSms() {
      return this.$permissions.user.can(PERMISSIONS.SIMS_AND_SMS.SEND_SMS)
    },
  },
  async mounted() {
    this.ownerItems = [this.selectedOwner]
    this.$root.$on("camera-updated", this.updateCameraItem)
  },
  created() {
    this.headers = headers(this)
    this.filterFields = filterFields(this)
    this.reportStore.initialSearchParams = {
      ...this.reportStore.initialSearchParams,
      cameraOwner: this.selectedOwner?.name,
    }
  },
  methods: {
    doSearch() {
      this.reportStore.searchFilters = {
        cameraOwner: this.selectedOwner?.name,
      }
    },
    save(exid, status) {
      try {
        AdminApi.cameras.update(exid, {
          status: status,
        })
        this.$notifications.success("Camera status updated successfully.")
      } catch (error) {
        this.$notifications.error({
          text: "Update camera status failed!",
          error,
        })
      }
    },
    async onChange(item) {
      if (item.status === CameraStatus.Decommissioned) {
        if (
          await this.$confirmDialog.open({
            title: "Confirm new status ?",
            message: "This will permanently decommission the camera!",
          })
        ) {
          this.save(item.exid, item.status)
        }
      }
    },
    async fetchLatestNotes() {
      this.reportStore.loading = true
      let camera_ids = this.reportStore.items.map((c) => c.id)
      try {
        const notes = await AdminApi.offlineCameras.getOfflineCamerasLastNote({
          cameraIds: camera_ids,
        })
        const publicNotes = notes.publicNotes.reduce((acc, note) => {
          return {
            ...acc,
            [note.cameraId]: note,
          }
        }, {})
        const privateNotes = notes.privateNotes.reduce((acc, note) => {
          return {
            ...acc,
            [note.cameraId]: note,
          }
        }, {})
        this.reportStore.items = this.reportStore.items.map((camera) => {
          return {
            ...camera,
            publicNote: this.getFormattedNote(publicNotes[camera.id]),
            privateNote: this.getFormattedNote(privateNotes[camera.id]),
          }
        })
      } catch (error) {
        this.$notifications.error({
          text: "Failed to fetch the latest notes!",
          error,
        })
      } finally {
        this.reportStore.loading = false
      }
    },
    getFormattedNote(note) {
      if (!note) {
        return ""
      }

      return `${this.$moment(note.insertedAt).format()} | ${note.username} | ${
        note.customMessage
      }`
    },
    openSmsHistoryDialog(item) {
      this.showSmsHistoryDialog = true
      this.selectedSimNumber = item.number
      this.selectedSim = item
    },
    updateCameraItem() {
      this.reportStore.items = this.reportStore.items.map((camera) => {
        return camera.exid === this.cameraDialogStore.camera.exid
          ? this.cameraDialogStore.camera
          : camera
      })
    },
    getPowerTypeText(powerType) {
      return PowerTypes.find((el) => el.value === powerType)?.name || "-"
    },
    getPowerScheduleText(powerSchedule) {
      return (
        PowerSchedules.find((el) => el.value === powerSchedule)?.name || "-"
      )
    },
    openBatteryReadingsDialog(cameraExid) {
      this.showBatteryReadingsDialog = true
      this.selectedCameraExid = cameraExid
    },
    getCameraDialogUrl(cameraExid) {
      return `${document.location.href}#dialog=true&exid=${cameraExid}`
    },
  },
}
</script>

<style scoped>
.camera-note {
  white-space: initial;
}
.low-voltage {
  background-color: #ffcdd2 !important;
}
</style>
